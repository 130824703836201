 import * as types from '../mutation-types'
 import ls from '../../utils/ls'
 import Vue from 'vue'
 const state = {
     pagelist: [],
 }

 const getters = {
     pagelist: state => state.pagelist,
 }

 const actions = {
     initPage({
         commit,
         state
     }, pagelist) {
         commit(types.PAGE_LIST, pagelist)
     },
     addPage({
         commit,
         state
     }, page) {
         commit(types.PAGE_ADD, page)
     },
     closePage({
         commit,
         state
     }, page) {
         commit(types.PAGE_REMOVE, page)
     },
     clearPage({
         commit,
         state
     }, page) {
         commit(types.PAGE_CLEAR, page)
     },
     closeOtaPage({
         commit,
         state
     }, page) {
         commit(types.PAGE_CLOSE_OTA, page)
     },
     closeCurrentPage({
         commit,
         state
     }, page) {
         commit(types.PAGE_CLOSE_CURRENT, page)
     }
 }

 const mutations = {
     //页面
     [types.PAGE_LIST](state, pagelist) {
         state.pagelist = pagelist
         util.savePageListCache(state.pagelist)
     },
     //新增页面
     [types.PAGE_ADD](state, page) {
         let needAdd = true;
         for (const item of state.pagelist) {
             if (item.path == page.path) {
                 item.open = true
                 item.title = page.title
                 item.params = page.params //
                 item.query = page.query //
                 needAdd = false

             } else {
                 item.open = false;
             }
         }
         if (needAdd) {
             state.pagelist.push(page);
             //
         }
         util.savePageListCache(state.pagelist)
     },
     //删除页面
     [types.PAGE_REMOVE](state, pg) {
         //删除页面实例
         //1.当前被关闭的页面路由对应的cacheKey
         let cacheKey = pg.pageM.cid
         //2.遍历所有的cache 找出cacheKey删除之
         util.removeCache(cacheKey, pg.ctx.$root.$children[0].$refs.mainScroller.$children, 1)

         state.pagelist = state.pagelist.filter(item => { //
             return item.path != pg.pageM.path
         })
         //打开pagelist中最后一个菜单
         const page = state.pagelist[state.pagelist.length - 1];
         if (pg.ctx.$route.path != page.path) { //如果页面已打开
             pg.ctx.$router.push({
                 name: page.name,
                 params: page.params,
                 query: page.query
             })
         }
         util.savePageListCache(state.pagelist)
     },
     //清除所有页面
     [types.PAGE_CLEAR](state, pg) {
         //删除所有页面 保留第一个页面
         //1.当前被关闭的页面路由对应的cacheKey
         let cacheKey = util.getCacheKey(pg.ctx.$route.matched[0].instances.content.$vnode)
         //2.遍历所有的cache 找出cacheKey删除之
         util.removeCache(cacheKey, pg.ctx.$root.$children[0].$refs.mainScroller.$children, -1)
         state.pagelist = [{
             title: '首页',
             open: true,
             name: 'home',
             path: '/home',
             closeable: false
         }]

         pg.ctx.$router.push({
             name: 'home'
         })

         util.savePageListCache(state.pagelist)
     },
     //关闭其他页面
     [types.PAGE_CLOSE_OTA](state, pg) {
         let cacheKey = pg.pageM.cid
         util.removeCache(cacheKey, pg.ctx.$root.$children[0].$refs.mainScroller.$children, 0)
         state.pagelist = state.pagelist.filter(item => {
             return (item.path == pg.pageM.path || item.name == "home")
         })
         const page = state.pagelist[state.pagelist.length - 1];
         if (pg.ctx.$route.path != page.path) { //如果页面已打开
             pg.ctx.$router.push({
                 name: page.name,
                 params: page.params,
                 query: page.query
             })
         }
         util.savePageListCache(state.pagelist)
     },
     //关闭当前页面
     [types.PAGE_CLOSE_CURRENT](state, pg) {
         //获取当前页 opeen=true
         const currentPage = state.pagelist.filter(item => {
             return item.open == true
         })[0]

         let cacheKey = currentPage.cid
         util.removeCache(cacheKey, pg.ctx.$root.$children[0].$refs.mainScroller.$children, 1)

         state.pagelist = state.pagelist.filter(item => { //
             return item.path != currentPage.path
         })
         if (typeof (pg.autoPre) == 'undefined') pg.autoPre = true
         //打开上一个菜单
         if (pg.autoPre) {
             pg.ctx.$router.go(-1);
         }


         util.savePageListCache(state.pagelist)
     }
 }
 const util = {
     getCacheKey(vnode) {
         if (vnode && vnode.data.keepAlive) {
             return vnode.key == null ? vnode.componentOptions.Ctor.cid + (vnode.componentOptions.tag ? `::${vnode.componentOptions.tag}` : '') :
                 vnode.key;
         }
     },
     doRemove(cache, keys, key) {
         if (cache[key]) {
             if (keys.length) {
                 var index = keys.indexOf(key);
                 if (index > -1) {
                     keys.splice(index, 1);
                 }
             }
             cache[key].componentInstance.$destroy()
             delete cache[key]; //删除页面缓存
         }
     },
     removeCache(cachekey, cachePages, not) {
         for (const i of cachePages) {
             if (i.$vnode && i.$vnode.data.keepAlive) {
                 if (i.$vnode.parent && i.$vnode.parent.componentInstance && i.$vnode.parent.componentInstance.cache) {
                     if (i.$vnode.componentOptions) {
                         var key = i.$vnode.key == null ?
                             i.$vnode.componentOptions.Ctor.cid + (i.$vnode.componentOptions.tag ? `::${i.$vnode.componentOptions.tag}` : '') :
                             i.$vnode.key;
                         //  var cache = i.$vnode.parent.componentInstance.cache;
                         //  var keys = i.$vnode.parent.componentInstance.keys;
                         if (not == 1) { //删除当前页缓存
                             if (key == cachekey) {
                                 this.doRemove(i.$vnode.parent.componentInstance.cache, i.$vnode.parent.componentInstance.keys, key)
                             }
                         } else if (not == 0) { //删除除当前页以外的页面缓存
                             if (key != cachekey) {
                                 this.doRemove(i.$vnode.parent.componentInstance.cache, i.$vnode.parent.componentInstance.keys, key)
                             }
                         } else if (not < 0) { //删除全部页面的缓存
                             this.doRemove(i.$vnode.parent.componentInstance.cache, i.$vnode.parent.componentInstance.keys, key)
                         }

                     }
                 }
             }
         }
     },
     savePageListCache(pagelist) {
         if (Vue.prototype.__SYSTEMCODE) {
             ls.set(Vue.prototype.__SYSTEMCODE + '_pagelist', state.pagelist)
         } else {
             alert('请配置系统编码')
         }



     }
 }
 export default {
     namespaced: true,
     state,
     getters,
     actions,
     mutations
 }