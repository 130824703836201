<template>
  <div class="in-header" ref="consoleHeader" v-if="showData">
    <div class="header-content">
      <div class="main-title">
        <div class="logo">{{ logo.logo }}</div>
        <div class="title">{{ logo.text }}管理系统</div>
      </div>
      <div class="sys-head-nav">
        <el-row type="flex" justify="end" align="middle">
          <div class="rightGap" v-if="importNowData == 0 && importData == 0">
            <i class="el-icon-upload2 bellClass" @click="goImport" title="查看导入任务"></i>
          </div>

          <el-button-group class="rightGap btnGroup">
            <el-button v-if="importNowData != 0" @click="goImport">
              导入中 {{ importNowData ? importNowData : null }}
            </el-button>
            <el-button v-if="importData != 0" :class="{ errorTxt: importException }" @click="goImport">
              已导入 {{ importData ? importData : null }}
            </el-button>

            <!-- <el-button style="border-radius: 5px" v-if="importData != 0 && importException == false" @click="goImport">
                已导入{{ importData ? importData : null }}
              </el-button> -->
          </el-button-group>
          <!--            <div class="importData" @click="goImport" style="cursor: pointer;">
                          <span v-if="importNowData != 0"
                                style="margin-left: 30px; border-radius: 5px;cursor: pointer;">正在导入 <span>{{
                              importNowData ? importNowData : null
                            }}</span>
                          </span>
                          <el-divider direction="vertical" v-if="importNowData != 0 "></el-divider>
                          <span style=" border-radius: 5px;color: red"
                                v-if="importException == true">已导入<span>{{ importData ? importData : null }}</span></span>
                          <span style=" border-radius: 5px;" v-else>已导入<span>{{ importData ? importData : null }}</span></span>
                        </div> style="border-radius: 5px"-->
          <!-- </el-col>
          <el-col :span="8"> -->

          <div class="rightGap" v-if="exportNowData == 0 && exportData == 0">
            <i class="el-icon-download bellClass" @click="goExport" title="查看导出任务"></i>
          </div>
          <!-- <el-button  plain  @click="goHome()">返回首页</el-button> -->
          <el-button-group @click="goExport" class="rightGap btnGroup">
            <el-button v-if="exportNowData != 0" @click="goExport">
              导出中 {{ exportNowData ? exportNowData : null }}
            </el-button>
            <el-button v-if="exportData != 0" @click="goExport">
              已导出 {{ exportData ? exportData : null }}
            </el-button>
          </el-button-group>
          <!--            <div class="importData" style="cursor: pointer;" @click="goExport">
                          <span v-if="exportNowData != 0" style="margin-left: 30px; border-radius: 5px;        cursor: pointer;">正在导出 <span>{{
                              exportNowData ? exportNowData : null
                            }}</span>
                          </span>
                          <el-divider v-if="exportNowData != 0" direction="vertical"></el-divider>
                          <span v-if="exportData != 0" style=" border-radius: 5px;        cursor: pointer;">已导出<span>{{
                              exportData ? exportData : null
                            }}</span></span>
                        </div>-->
          <!-- </el-col>
          <el-col :span="4"> -->

          <div class="el-icon-sunrise" @click="goHome()"></div>

          <div class="sysBell">
            <el-badge :value="num" :hidden="num == 0">
              <i class="el-icon-bell bellClass" @click="showMessageBox"></i>
            </el-badge>
          </div>
        </el-row>
      </div>
      <div class="user-info" @click="showDetail = !showDetail">
        <img class="user-info-img" :src="avatarUrl" />
        <span class="user-info-name">
          <i :class="[showDetail ? 'user-info-arrow el-icon-arrow-up' : 'user-info-arrow  el-icon-arrow-down']"></i>
        </span>
      </div>
    </div>
    <!--    <transition name="fade">-->
    <!--      <user-info-panel-->
    <!--          :userinfo="userinfo"-->
    <!--          :avatar-url="avatarUrl"-->
    <!--          :panel-visiable="showDetail"-->
    <!--          :sing-out-fn="signOut"-->
    <!--          @hide-panel="hidePanel"-->
    <!--      ></user-info-panel>-->
    <!--    </transition>-->

    <ass-panel
      ref="assPanelRef"
      :userinfo="userinfo"
      :avatar-url="avatarUrl"
      :show-dialog.sync="showDetail"
      :sing-out-fn="signOut"
    />
    <el-drawer :visible.sync="msgBoxShow" direction="rtl" size="300px" class="msg-drawer" append-to-body>
      <div slot="title" class="msg-title">
        <div class="msg-title-left">消息中心</div>
        <div class="msg-title-right">
          <!-- <a class="msg-more" :href="msgUrl" @click="msgBoxShow = false">查看更多</a> -->
          <a class="msg-more" @click="toLookMore">查看更多</a>
        </div>
      </div>
      <div class="msg-body">
        <div
          v-for="(msg, index) in top5msg"
          :key="msg.id"
          class="msg-content"
          :class="{ 'msg-click': msg.status != '已读' }"
          @click="readMsg(msg, index)"
        >
          <div class="msg-header">
            <div class="msg-type">{{ msg.title }}</div>
            <div class="msg-time">{{ msg.create_time }}</div>
          </div>
          <div class="msg-con">{{ msg.message }}</div>
        </div>
      </div>
    </el-drawer>

    <export-task-template ref="export" @complete=""></export-task-template>
    <import-task-template ref="import" @complete=""></import-task-template>
  </div>
</template>

<script setup>
import { computed } from "vue";

const logo = computed(() => {
  const href = window.location.href;
  if (href.includes("wms")) {
    return {
      logo: "WMS",
      text: "仓储",
    };
  }
  return {
    logo: "TMS",
    text: "运输",
  };
});
</script>

<script>
import Tree from "./tree";
import {
  mapActions,
  mapGetters,
  //mapGetters
} from "vuex";
import { findTypeInMenuType } from "@/utils/utils";
import whEntityAPI from "@/project/wms/api/wmsEntityApi.js";
//import UserInfoPanel from "./UserInfoPanel.vue";
import AssPanel from "./AssistantUserPanel.vue";
import userAPI from "../api/userAPI.js";
import Vue from "vue";
import aEntityAPI from "@/api/aEntityAPI.js";
import io from "./socket.io";
import ExportTask from "@/project/common/views/task/exportTaskTemplate";
import ExportTaskTemplate from "@/project/common/views/task/exportTaskTemplate";
import importTaskTemplate from "@/project/common/views/task/importTaskTemplate";
// import vm from '@/utils/utils.js'

export default {
  components: {
    ExportTaskTemplate,
    importTaskTemplate,
    ExportTask,
    AssPanel,
    //UserInfoPanel,
    // IndentitySwitch,
    Tree,
  },
  // computed: {
  //   ...mapGetters({
  //     userinfo: "user/userinfo",
  //     sysConfig: "config/sysConfig"
  //   }),
  //   ...mapActions(["config/setConfig"])
  // },
  props: {
    showSystemMenu: {
      type: Boolean,
      default: true,
    },
    showData: {
      type: Boolean,
      default: true,
    },
  },

  watch: {
    sysConfig: function (nval, oval) {
      if (nval && nval.logoFile && nval.logoFile.filePath) {
        var link = document.createElement("link");
        link.type = "image/x-icon";
        link.rel = "shortcut icon";

        var serverHost = window.location.host;
        if (serverHost.indexOf("hrsaas") > -1 || serverHost.indexOf("saas.gz-vip.com") > -1) {
          link.href = this.hrsaasImg;
        } else if (serverHost.indexOf("saas") > -1) {
          link.href = this.saasImg;
        } else {
          link.href = this.__ftpPath + nval.logoFile.filePath;
        }
        document.getElementsByTagName("head")[0].appendChild(link);
      }
    },
  },

  data() {
    return {
      importData: 0,
      importNowData: 0,
      exportData: 0,
      exportNowData: 0,
      importException: false,
      // showData: true,
      whWebSocket: null,
      logoUrl: "",
      logoUrl1: "",
      sysName: "",
      num: 0,
      img: null,
      msgBoxShow: false,
      showDetail: false,
      pathname: window.location.pathname,
      msgUrl: "common.html#/msgList",
      top5msg: [{}],
      showMenu: true,
      hrsaasImg: require("@/assets/hrsaas/index/hr_saas_logo.png"),
      saasImg: require("@/assets/saas_logo.png"),
    };
  },

  computed: {
    ...mapGetters({
      userinfo: "user/userinfo",
      sysConfig: "config/sysConfig",
      shipper: "user/shipper",
    }),

    showIndentitySwitch() {
      return false;

      // var pathName = window.location.pathname;
      // // 运营系统无需切换货主
      // if (pathName.indexOf("manage") > -1) {
      //   return false;
      // } else {
      //   if (
      //     this.userinfo.roles.filter(
      //       (item) =>
      //         item.code == "administrators_gonghe" ||
      //         item.code == "administrators_gfz"
      //     ).length > 0
      //   ) {
      //     return true;
      //   } else {
      //     return false;
      //   }
      // }
    },

    avatarUrl() {
      const imgBaseUrl = this.__ftpPath;
      if (this.userinfo.profileImg) return imgBaseUrl + this.userinfo.profileImg.filePath;
      else return require("../assets/avatar5.png");
    },
  },
  methods: {
    ...mapActions(["user/logout", "page/clearPage"]),
    hidePanel() {
      this.showDetail = false;
    },
    //查看更多
    toLookMore(){
      this.msgBoxShow = false
      this.$router.push({
          name: "msgList",
      });
    },
    /**
     * 打开导出窗口
     */
    goExport() {
      this.queryTaskCount();
      this.$refs.export.show();
    },

    /**
     * 打开导入窗口
     */
    goImport() {
      this.queryTaskCount();
      this.$refs.import.show();
    },

    // 返回首页
    goHome() {
      window.location.href = "/hPage.html#/hPage";
    },
    menuCLick(ctx) {
      let type = findTypeInMenuType(ctx.parentMenu);
      if (type) {
        const path = "/" + type + ".html";
        const url = path + "#" + ctx.route.path;
        if (path != window.location.pathname) {
          window.open(url, "_blank");
        }
      }
    },
    showMessageBox() {
      this.msgBoxShow = true;
    },
    signOut() {
      this["user/logout"]().then((data) => {
        if (data) {
          window.location.href = "/login.html";
        }
      });
    },
    readMsg(msg, index) {
      userAPI
        .readMsgById(msg.msgId)
        .then((res) => {
          if (res.data.success) {
            this.$set(this.top5msg[index], "status", "已读");
            this.loadUnReadMsg();
            // }
          } else {
            this.$message({
              showClose: true,
              message: res.data.msg ? res.data.msg : "更改已读状态失败",
              type: "warning",
            });
          }
        })
        .catch((res) => {});
    },
    loadUnReadMsg() {
      userAPI
        .getUserUnreadMsgCount(this.userinfo.id)
        .then((res) => {
          if (res.data.success) {
            this.num = res.data.data[0].num;
          }
        })
        .catch((res) => {});
    },
    loadUnReadMsgTop5() {
      userAPI
        .getUnreadMsgTop5(this.userinfo.id)
        .then((res) => {
          if (res.data.success) {
            this.top5msg = res.data.data;
          }
        })
        .catch((res) => {});
    },
    loadCompanyUrl() {
      this.logoUrl1 = "xthy.png";
      if (this.sysConfig.logoFile && this.sysConfig.logoFile.filePath) {
        this.logoUrl = this.__ftpPath + this.sysConfig.logoFile.filePath;
        if (this.userinfo && this.userinfo.roles) {
          this.userinfo.roles.forEach((role) => {
            if (role.code == "tax_bureau") {
              this.logoUrl = "";
            }
          });
        }
      }
    },

    //仓配socket ---start-----
    initSocket() {
      let that = this;
      let opts = {
        transports: ["websocket"],
      };

      var defUrl = "http://192.168.20.234:3000";
      var testUrl = "http://60.169.85.105:3003";
      var proUrl = "https://dulogistics.baiyihulian.com:3000";

      var wsUrl =
        process.env.VUE_APP_MODE === "PRO"
          ? proUrl
          : process.env.VUE_APP_MODE === "TEST"
          ? testUrl
          : defUrl;
      console.log(wsUrl);
      that.whWebSocket = io.connect(wsUrl, opts);
      that.whWebSocket.on("connect", function (obj) {
        console.log("connect:" + obj);
      });
      that.whWebSocket.on("webPush", function (obj) {
        console.log("webPush:" + obj);
      });
      that.whWebSocket.on("disconnect", function (obj) {
        console.log("disconnect:" + obj);
        that.whWebSocket.emit("connect", "dataPice");
      });

      that.whWebSocket.on("ShpEventMonitor", function (obj) {
        console.log("ShpEventMonitor:" + obj);
        // that.checkWhSocketInfo(obj);
        that.$message.success("您有新的推送消息");
        that.loadUnReadMsgTop5(); //主动刷新小铃铛得信息
        that.loadUnReadMsg();
      });
      that.whWebSocket.on("tdoMonitor", function (obj) {
        if (that.userinfo && obj && obj.event) {
          if (obj.event.userId === that.userinfo.id + "") {
            if (obj.event.type == "销售订单待审") {
              that.$notify({
                title: obj.event.type || "提醒",
                message: obj.event.msg,
                type: "success",
              });
            } else {
              that.$notify({
                title: obj.event.type || "警告",
                message: obj.event.msg,
                type: "warning",
              });
            }

            that.loadUnReadMsgTop5(); //主动刷新小铃铛得信息
            that.loadUnReadMsg();
          }
        }
      });
      that.whWebSocket.on("grAudit", function (obj) {
        // console.log("grAudit:" + obj);
        if (that.userinfo && obj && obj.event) {
          if (obj.event.userId === that.userinfo.id + "") {
            console.info("userinfo", that.userinfo);
            that.$message.success("您有新的共融审核提醒");
            that.loadUnReadMsgTop5();
            that.loadUnReadMsg();
          }
        }
      });
      //仓配得socket消息接收
      that.whWebSocket.on("distribution", function (obj) {
        console.log("distribution:" + obj);
        that.$message.success("仓配推送单有消息更新");
        // that.checkWhSocketInfo(obj);
      });

      that.whWebSocket.on("webPush", function (obj) {
        console.log("webPush:" + obj);
        // that.checkWhSocketInfo(obj);
        that.$message.success("您有新的推送消息");
        that.loadUnReadMsgTop5(); //主动刷新小铃铛得信息
        that.loadUnReadMsg();
      });
    },

    //处理推送的数据
    checkWhSocketInfo(reData) {
      let that = this;
      if (reData == null) {
        return;
      }
      //转换字符串
      var reData = JSON.parse(reData);
      if (reData.userId == null || reData.userId != that.userinfo.id || reData.event == null || reData.event == "") {
        return;
      }

      that.loadUnReadMsgTop5(); //主动刷新小铃铛得信息
      whEntityAPI
        .request("WhSocketApiService", "checkWhSocketInfo", {
          dataDict: JSON.stringify(reData.event),
        })
        .then((res) => {
          if (res.data.success) {
            that.$message.success("您有新的推送消息");
            that.loadUnReadMsgTop5();
          } else {
            that.$message({
              showClose: true,
              message: res.data.msg ? res.data.msg : "消息推送失败",
              type: "warning",
            });
          }
        })
        .catch((res) => {});
    },

    handALogOrName() {
      var serverHost = window.location.host;
      if (serverHost.indexOf("hrsaas") > -1 || serverHost.indexOf("saas.gz-vip.com") > -1) {
        this.img = this.hrsaasImg;
        this.sysName = "工众人力SAAS平台";
      } else if (serverHost.indexOf("saas") > -1) {
        this.img = this.saasImg;
        this.sysName = "物流SAAS平台";
      }

      console.log("this.sysName:" + this.sysName);
      console.log("this.img:" + this.img);
    },

    /**
     * 刷新数量
     */
    queryTaskCount() {
      aEntityAPI
        .request("AsyncTaskService", "queryTaskCount", {})
        .then((res) => {
          if (res.data.success) {
            var data = res.data.data;
            this.importNowData = data.importNowData;
            this.importData = data.importData;
            this.exportNowData = data.exportNowData;
            this.exportData = data.exportData;
            this.importException = data.importException;
          } else {
            // console.log("请求成功")
          }
        })
        .catch((_) => {
          // _this.btnLoading.all = false
        });
    },

    //仓配socket ---end-----
  },
  mounted() {
    this.loadUnReadMsg();
    this.loadUnReadMsgTop5();
    this.loadCompanyUrl();
    this.handALogOrName();

    //需要定时执行的代码
    if (!(process.env.NODE_ENV === "development")) {
      let timer = setInterval(() => {
        this.queryTaskCount();
      }, 30000);
    }
  },

  created() {
    this.initSocket();
  },

  destroyed() {},
};
</script>

<style lang="scss">
.in-header {
  height: 50px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
  color: #c0c4cc;
  background-color: #fff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
}

.header-content {
  width: 100%;
  margin: 0 auto;
}

.main-title {
  margin-left: 24px;
  margin-top: 15px;
  display: flex;
  .logo {
    font-size: 16px;
    font-weight: 600;
    background: $--color-primary;
    color: #fff;
    padding: 2px 12px;
    border-radius: 2px;
  }
  .title {
    height: 22px;
    font-size: 16px;
    font-weight: 600;
    color: #333;
    margin-left: 4px;
  }
}

.in-header > div > span {
  font-size: 18px;
  color: #c0c4cc;
  font-weight: bold;
  height: 50px;
  line-height: 50px;
  width: 180px;
  text-align: center;
  display: inline-block;
}

.msg-drawer .el-drawer__header {
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
  margin-bottom: 0px;
}

.msg-click {
  color: #409eff;
  cursor: pointer;
}

.msg-title {
  display: flex;
  height: 20px;
  line-height: 20px;
}

.msg-title-left {
  flex: 1;
  font-size: 16px;
}

.msg-title-right {
  flex: 1;
  text-align: right;
  padding-right: 20px;
}

.msg-more {
  color: #409eff;
  cursor: pointer;
}

.msg-content {
  margin: 10px;
  border-bottom: 1px solid #eee;
}

.msg-header {
  display: flex;
  padding: 10px 0;
}

.msg-click .msg-type,
.msg-click .msg-con,
.msg-click .msg-time {
  color: #409eff;
}

.msg-type {
  flex: 1;
  color: #888;
}

.msg-con {
  padding-bottom: 10px;
}

.msg-time {
  flex: 1;
  color: #888;
  text-align: right;
}

.user-info {
  position: absolute;
  top: 0px;
  right: 10px;
  color: #c0c4cc;
  height: 50px;
  line-height: 50px;
  margin-right: 15px;
}

.user-info-detail {
  position: absolute;
  display: inline-block;
  width: 240px;
  background: #333333;
  height: 100px;
  right: 0;
}

.img-info {
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #c0c4cc;
  padding: 5px 10px;
  overflow: hidden;
}

.img-info img {
  width: 40px;
  height: 40px;
  vertical-align: middle;
  border-radius: 50%;
}

.img-info > span {
  color: #fff;
  padding-left: 10px;
}

.quick-entry a {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.quick-entry a > i {
  font-size: 14px;
}

.quick-entry .el-row {
  padding: 10px;
}

.quick-entry .el-col {
  text-align: center;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.rightGap {
  margin-right: 20px;
}

.sysBell {
  /* display: inline-block; */
  /* width: 25px; */
  vertical-align: sub;
  cursor: pointer;
  /* padding-left: 20px; */
  margin-right: 20px;
}

.importData {
  width: 180px;
  background-color: #f1f1f1ff;
  height: 30px;
  margin: 0 auto;
  margin-top: 10px;
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 5px;
}

.bellClass {
  font-size: 18px;
  color: $--color-primary;
  cursor: pointer;
}

.sys-head-nav {
  position: absolute;
  height: 50px;
  top: 0;
  right: 80px;
  line-height: 50px;
  width: 500px;
}

.sys-menu-wrap {
  display: inline-block;
}

.sys-menu-wrap .ellink {
  text-align: center;
  margin: 0px 20px;
}

.sys-menu-wrap > ul {
  display: flex;
}

.sys-menu-wrap > ul > li {
  float: left;
  height: 50px;
  line-height: 50px;
  color: #fff;
  flex: 1;
  text-align: center;
}

.sys-menu-wrap > ul > li > a {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}

.sys-menu-wrap .el-menu--horizontal > .el-submenu .el-submenu__title {
  height: 50px;
  line-height: 50px;
}

.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  border-bottom: none;
  color: #fff;
}

.sys-menu-wrap .el-link.el-link--default {
  color: #fff;
}

.user-info > img {
  width: 40px;
  height: 40px;
  padding-top: 5px;
  border-radius: 50%;
}

.user-info-name {
  position: absolute;
  padding-left: 5px;
  cursor: pointer;
}

.user-info-img {
  cursor: pointer;
}

.sysBell .el-badge__content.is-fixed {
  top: 8px;
}
.errorTxt {
  color: red;
}
.btnGroup {
  opacity: 0.9;
  background: #4e4e4e;
}

.el-icon-sunrise {
  background: url("../assets/datav/sy.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 20px;
  height: 18px;
  margin-right: 40px;
  margin-bottom: 6px;
}

.el-icon-sunrise:before {
  content: "11";
  visibility: hidden;
}
.el-icon-sunrise:hover {
  cursor: pointer;
}
</style>
