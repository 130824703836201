<template>
  <div class="gs-selector-dropdown" v-show="opened">
    <div class="gs-selector-search">
      <el-input
        v-model="query"
        @input="queryChange"
        ref="queryInput"
        @click="toggleMenu"
        :size="size"
        :placeholder="searchplaceholder"
      ></el-input>
    </div>
    <ul class="gs-selector-dropdown-ul" v-loading="loading">
      <li class="gs-selector-dropdown-item" v-for="(item, index) in data" :key="index" @click.stop="select(item)">
        <slot :item="item">
          <render-item :item="item" :format-item="formatResult" :width="selectorWidth" :show-type="showType">
          </render-item>
        </slot>
      </li>
      <li class="gs-selector-dropdown-item" v-if="data.length == 0">{{ nodatatext }}</li>
    </ul>
    <div class="gs-selector-paging" v-if="paging">
      <el-pagination
        small
        :page-size="pageSize"
        layout="total,prev, pager, next"
        :total="total"
        @current-change="pageChange"
        :current-page="pageNo"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import RenderItem from "./item";
export default {
  components: {
    RenderItem,
  },
  props: {
    size: String,
    opened: Boolean,
    query: String,
    loading: Boolean,
    toggleMenu: Function,
    select: Function,
    data: Array,
    showType: String,
    formatResult: Function,
    selectorWidth: Number,
    searchplaceholder: String,
    nodatatext: String,
    paging: Boolean,
    pageSize: Number,
    pageNo: Number,
    total: Number,
    pageChange: Function,
    dropAppendToBody: Boolean,
  },
  data() {
    return {
      queryValue: this.query,
    };
  },
  methods: {
    queryChange() {
      this.$emit("queryChange", this.query);
    },
  },
  mounted() {
    if (this.opened) {
      if (this.dropAppendToBody) {
        document.body.appendChild(this.$el);
      }
    }
  },
  watch: {
    opened(val) {
      if (val) {
        if (this.dropAppendToBody) {
          document.body.appendChild(this.$el);
        }
        //展开自动focus 输入框
        this.$nextTick(() => {
          this.$refs.queryInput.focus();
        });
      }
    },
  },
  destroyed() {
    if (this.dropAppendToBody && this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
  },
};
</script>
