<template>
  <div
    :class="['gs-selector', selectorSize ? 'gs-selector--' + selectorSize : '', { 'is-disabled': selectorDisabled }]"
    v-clickoutside="handleClose"
  >
    <div class="gs-selector-selection" @click="toggleMenu" ref="selection">
      <template v-if="multi">
        <span class="gs-selector-placeholder" v-show="currentValue == null || currentValue.length == 0">
          {{ placeholder }}
        </span>
        <el-tag
          v-for="(item, index) in currentValue"
          :key="index"
          :closable="!selectorDisabled"
          @close="handleRemove(item)"
          type="info"
          disable-transitions
        >
          <render-item :set-width="true" :item="item" :format-item="formatSelection" :width="selectorWidth">
          </render-item>
        </el-tag>
      </template>
      <template v-else>
        <!-- {{emptyValue}} -->
        <span class="gs-selector-placeholder" v-show="emptyValue">
          {{ placeholder }}
        </span>
        <el-tag
          :closable="!selectorDisabled"
          @close="handleRemove(currentValue)"
          v-if="!emptyValue"
          type="info"
          disable-transitions
        >
          <render-item :set-width="true" :item="currentValue" :format-item="formatSelection" :width="selectorWidth">
          </render-item>
        </el-tag>
      </template>
    </div>
    <div class="gs-selector-arrow">
      <i class="el-icon-arrow-down" @click="toggleMenu" :class="{ 'is-reverse': opened }"></i>
    </div>
    <drop
      :opened="opened"
      :searchplaceholder="searchplaceholder"
      :nodatatext="nodatatext"
      ref="drop"
      :style="dropDownStyle"
      :size="size"
      :drop-append-to-body="dropAppendToBody"
      :format-result="formatResult"
      :selector-width="selectorWidth"
      :page-size="pageSize"
      :page-no="pageNo"
      :total="total"
      :show-type="showType"
      :page-change="pageChange"
      :query="query"
      @queryChange="handleQueryChange"
      :toggle-menu="toggleMenu"
      :paging="paging"
      :loading="loading"
      :select="select"
      :data="data"
      v-slot="slotProps"
      :judgeCopy="judgeCopy"
    >
      <slot :item="slotProps.item"></slot>
    </drop>
  </div>
</template>
<script>
import emitter from "element-ui/src/mixins/emitter";
import RenderItem from "./item";
import Drop from "./drop.vue";
import debounce from "throttle-debounce/debounce";
import clickoutside from "../clickoutside";
import { arrayRemove, isEmptyObject } from "../../utils/utils";
import { mapGetters } from "vuex";
export default {
  name: "GsSelector",
  components: {
    Drop,
    RenderItem,
  },
  mixins: [emitter],
  directives: {
    clickoutside,
  },
  inject: {
    elForm: {
      default: "",
    },
    elFormItem: {
      default: "",
    },
  },
  props: {
    judgeCopy: {
      //判断是否是复制
      type: String,
    },
    value: {
      type: [Array, Object],
      default() {
        return [];
      },
    },
    placeholder: {
      type: String,
      default: "请选择",
    },
    searchplaceholder: {
      type: String,
      default: "请输入关键词搜索",
    },
    nodatatext: {
      type: String,
      default: "暂无数据",
    },
    size: String,
    url: {
      type: String,
      required: true,
    },
    getParam: {
      //尽情组装查询参数
      type: Function,
      required: true,
    },
    customerParam: {
      //自定义参数
      type: Object,
      required: false,
    },
    formatResult: {
      //处理下拉选项的显示
      type: Function,
    },
    showType: {
      //处理下拉选项的显示
      type: String,
      required: false,
    },
    formatSelection: {
      //处理选择值显示
      type: Function,
    },
    paging: {
      //是否分页
      type: Boolean,
      default: true,
    },
    pageSize: {
      //每页条数  用于分页
      type: Number,
      default: 10,
    },
    multi: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dropAppendToBody: {
      type: Boolean,
      default: false,
    },
    beforeOpen: {
      type: Function,
    },
    scrollTopTag:{
      type: Boolean,
      default: true,
    }
  },
  computed: {
    ...mapGetters({
      scrollTop: "layout/scrollTop",
    }),
    dropDownStyle() {
      let style = {};
      if (!this.dropAppendToBody) {
        style.top = this.selHeight + "px";
      } else {
        var scrollTop = this.scrollTopTag ? this.scrollTop : 0;
        style.top = this.absoulteTop + this.selHeight - scrollTop + "px";
        style.left = this.absoulteLeft + "px";
      }
      style.minWidth = this.selWidth + "px";
      // console.log("absoulteTop:" + this.absoulteTop);
      // console.log("selHeight:" + this.selHeight);
      // console.log("scrollTop:" + this.scrollTop);
      return style;
    },
    _elFormItemSize() {
      //继承表单的size
      return (this.elFormItem || {}).elFormItemSize;
    },
    selectorSize() {
      return this.size || this._elFormItemSize || (this.$ELEMENT || {}).size;
    },
    // selectorWidth() {
    //     return this.$refs.selection.offsetWidth
    // },
    selectorDisabled() {
      return this.disabled || (this.elForm || {}).disabled;
    },
    emptyValue() {
      return isEmptyObject(this.currentValue);
    },
  },
  data() {
    return {
      query: "",
      currentValue: this.value === undefined || this.value === null ? (this.multi ? [] : this.value) : this.value,
      data: [],
      total: 0,
      pageNo: 1,
      opened: false,
      selHeight: 0,
      selWidth: 0,
      selectorWidth: 0,
      loading: false,
      absoulteTop: 0,
      absoulteLeft: 0,
    };
  },

  created() {
    this.debouncedGetData = debounce(500, this.doSearch);
  },

  methods: {
    handleClose(e) {
      this.opened = false;
      this.$emit("on-selector-close");
    },
    toggleMenu() {
      this.query = "";
      if (this.selectorDisabled) {
        return false;
      }
      if (this.beforeOpen && !this.beforeOpen()) {
        return false;
      }
      this.updateSelectHeight();
      this.opened = !this.opened;
      if (this.opened) {
        this.doSearch();
      }
    },
    updateSelectHeight() {
      this.selWidth = this.$refs.selection.offsetWidth;
      this.selHeight = this.$refs.selection.offsetHeight;
      this.absoulteTop = this.$refs.selection.getBoundingClientRect().top;
      this.absoulteLeft = this.$refs.selection.getBoundingClientRect().left;
    },

    clearAll() {
      //清空所有选项、值
      this.data = [];
      this.currentValue = this.multi ? [] : null;
      this.value = this.currentValue;
      this.$emit("change", this.value, true);
      console.log("selector清空元素:" + this.$vnode.data.ref);
    },

    doSearch() {
      let start = (this.pageNo - 1) * this.pageSize;
      let q = this.getParam(start, this.query, this.customerParam);
      this.loading = true;

      this.$http
        .post(this.url, q)
        .then((response) => {
          this.loading = false;
          const data = response.data;
          if (data.success) {
            this.data = data.data;
            if (this.paging) {
              this.total = data.total;
            }

            if (this.opened) {
              this.updateSelectHeight();
            }

            if (this.data.length == 1) {
              if (this.opened == false) {
                //就一个，并且未打开opened，后台自动选中，并触发change
                console.log("selector[" + this.$vnode.data.ref + "]自动选中首元素");
                this.select(this.data[0]);
              }
            } else if (this.data.length > 1) {
              this.opened = true;
            } else {
              //无值
              this.$emit("change", this.value);
            }
          } else {
            // 请求失败后清除列表缓存数据 by  zq  21/01/21
            this.data = [];
          }
        })
        .catch((res) => {
          this.data = [];
        });
    },

    pageChange(pageNo) {
      this.pageNo = pageNo;
      this.doSearch();
    },

    handleRemove(item) {
      if (this.selectorDisabled) {
        return false;
      }
      if (this.multi) {
        let inx = null;
        this.currentValue.find((v, index) => {
          if (v["id"] == item["id"]) {
            inx = index;
          }
        });
        if (inx != null) {
          arrayRemove(this.currentValue, inx, inx);
        }
      } else {
        this.currentValue = null;
        console.log(isEmptyObject(this.currentValue));
      }
      this.value = this.currentValue;

      this.$emit("input", this.currentValue);
      this.$emit("change", this.currentValue);
      this.dispatch("ElFormItem", "el.form.change", this.currentValue); //触发所在表单的校验事件
    },

    isEmptyObject(obj) {
      return isEmptyObject(obj);
    },

    checkIsExist(item) {
      if (!this.multi) {
        return false;
      }
      for (const d of this.value) {
        if (d["id"] == item["id"]) {
          return true;
        }
      }
      return false;
    },

    select(item) {
      const exist = this.checkIsExist(item);
      if (exist) {
        return;
      }

      if (!this.multi) {
        this.currentValue = item;
        this.opened = false;
      } else {
        if (this.currentValue == null) this.currentValue = [];
        this.currentValue.push(item);
      }
      this.value = this.currentValue;
      console.log(this.currentValue);

      this.$emit("input", this.currentValue);
      this.$emit("change", this.currentValue);
    },

    handleQueryChange(val) {
      this.pageNo = 1; //重置 页码
      this.query = val;
    },
  },
  watch: {
    query: function (val, oval) {
      this.debouncedGetData(val);
    },
    value: function (val, oval) {
      this.dispatch("ElFormItem", "el.form.change", val); //触发所在表单的校验事件
      this.currentValue = val;
      this.$nextTick(() => {
        this.updateSelectHeight();
      });
    },
    judgeCopy: function (val, oval) {
      if (val) {
        this.query = val;
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.selection) {
        this.selectorWidth = this.$refs.selection.getBoundingClientRect().width;
      }
    });
  },
};
</script>
<style>
.gs-selector-item {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}
.gs-selector-item-right {
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  float: right;
}
</style>
