import userAPI from '../../api/userAPI'
import * as types from '../mutation-types'
import ls from '../../utils/ls.js'
import Vue from 'vue'
const state = {
  authenticated: false,
  userinfo: {},
  organ:{},
  shipper: {},
  employer: {},
  team: {},
  menu: {},
  uda_customers: [],
  uda_organs: [],
  employerList: [],//发包方代理商关联发包方
}

const getters = {
  authenticated: state => state.authenticated,
  userinfo: state => state.userinfo,
  organ: state => state.organ,
  roles: state => state.userinfo.roles,
  shipper: state => state.shipper,
  team: state => state.team,
  menu : state => state.menu,
  employer : state => state.employer,
  uda_customers: state => state.uda_customers,
  uda_organs: state => state.uda_organs,
  employerList: state => state.employerList
}

const loginFun = function (commit, response) {
  if (response.status === 200 && response.data.success) {
    if (response.data.user.firstLogin) {
      ls.set('login_info', {
        mobile: response.data.user.mobile,
        firstLogin: response.data.user.firstLogin
      });
    } else {
      commit(types.USER_SET_AUTHENTICATE, true)
      commit(types.USER_SET_INFO, response.data.user)
      //将相关信息存贮在localstorage
      ls.set('sessionId', response.data.sessionId);
      ls.set('userInfo', response.data.user);
      //当前Organ
      if (response.data.user.organ) {
        commit(types.USER_SET_ORGAN, response.data.user.organ)
        ls.set('organ', response.data.user.organ);
      }
      if (response.data.uda_customers) {
        commit(types.USER_CUSTS, response.data.uda_customers)
        ls.set('uda_customers', response.data.uda_customers);
      }
      if (response.data.uda_organs) {
        commit(types.USER_ORGANS, response.data.uda_organs)
        ls.set('uda_organs', response.data.uda_organs);
      }
      
      if (response.data.shipper) {
        commit(types.USER_SET_SHIPPER, response.data.shipper)
        ls.set('shipper', response.data.shipper);
      }
      if (response.data.employer) {
        commit(types.USER_SET_EMPLOYER, response.data.employer)
        ls.set('employer', response.data.employer);
      }
      if (response.data.employerList) {
        commit(types.USER_SET_EMPLOYER_LIST, response.data.employerList)
        ls.set('employerList', response.data.employerList);
      }
      if (response.data.team) {
        commit(types.USER_SET_TEAM, response.data.team)
        ls.set('team', response.data.team);
      }
      commit(types.USER_SET_MENU, response.data['menuTree'])
      ls.set('menu', response.data['menuTree'])
      
      if(response.data['tenantInfo']){
        ls.set('tenantInfo', response.data['tenantInfo'])
      } else {
        ls.remove('tenantInfo')
      }

      // commit(types.CSRF_TOKEN, response.data['csrfToken'])
      ls.set('csrfToken', response.data['csrfToken'])
      ls.set('csrfSeq', 1)

    }

    let result = {};
    result.success = true;
    result.user = response.data.user;
    result.menu = response.data['menuTree']
    result.tenantInfo = response.data['tenantInfo']

    return Promise.resolve(result)
    //router.replace('/home')
  } else {
    let result = {};
    result.success = false;
    if (typeof (response.data) === 'string') {
      result.msg = JSON.parse(response.data)['msg'];
    }
    result.msg = response.data.message ? response.data.message : (response.data.msg ? response.data.msg : "");
    if (response.data && response.data.errorTimes) {
      result.errorTimes = response.data.errorTimes
    }
    return Promise.resolve(result)
  }
}

const actions = {
  login({
    commit,
    //  state
  }, creds) {
    return userAPI.login(creds).then((response) => {
      return loginFun(commit, response)
    })
  },

  autoLogin({
    commit,
    //  state
  }, creds) {
    return userAPI.autoLogin(creds).then((response) => {
      return loginFun(commit, response)
    })
  },

  logout({
    commit,
    //  state
  }) {
    return userAPI.logout().then((response) => {
      //删除localstorage上存贮的登录信息
      if (response.status === 200 || response.data.success) {
        commit(types.USER_SET_AUTHENTICATE, false)
        commit(types.USER_LOGOUT)
        //window.location.reload()
        ls.clear2()

        return Promise.resolve(true)
      } else {
        Vue.prototype.$message({
          showClose: true,
          message: '退出登录失败',
          type: 'error'
        });
        return Promise.resolve(false)
      }
    });





  },

  refreshCustomer({
    commit,
    //  state
  }) {
    return userAPI.refreshCustomer().then((response) => {
      //删除localstorage上存贮的登录信息
      if (response.status === 200 || response.data.success) {
        if (response.data.uda_customers) {
          commit(types.USER_CUSTS, response.data.uda_customers)
          ls.set('uda_customers', response.data.uda_customers);
        }

        return Promise.resolve(true)
      } else {
        Vue.prototype.$message({
          showClose: true,
          message: '更新客户缓存失败',
          type: 'error'
        });
        return Promise.resolve(false)
      }
    });
  },

  refreshShipper({
    commit,
    //  state
  },shipperId) {
    return userAPI.refreshShipper(shipperId).then((response) => {
      //删除localstorage上存贮的登录信息
      if (response.status === 200 || response.data.success) {
        const data =response.data
        //切换shipper
        if (data.shipper) {
          commit(types.USER_SET_SHIPPER, data.shipper)
          ls.set('shipper', data.shipper);
        }
         //切换organ
         if (data.organ) {
          commit(types.USER_SET_ORGAN, data.organ)
          ls.set('organ', data.organ);
        }
        //切换客户权限
        if (data.uda_customers) {
          commit(types.USER_CUSTS, data.uda_customers)
          ls.set('uda_customers', data.uda_customers);
        }

        //切换网点权限

        if (data.uda_organs) {
          commit(types.USER_ORGANS, data.uda_organs)
          ls.set('uda_organs', data.uda_organs);
        }

        //切换菜单权限
        if (data.menuTree) {
          commit(types.USER_SET_MENU, data.menuTree)
          ls.set('menu', data.menuTree)
        }

        return Promise.resolve(true)
      } else {
        Vue.prototype.$message({
          showClose: true,
          message: '切换失败',
          type: 'error'
        });
        return Promise.resolve(false)
      }
    });
  },
  inValidAuthenticate({
    commit
  }) {
    commit(types.USER_SET_AUTHENTICATE, false);
  },
  checkLogin({
    commit,
    // state
  }) {
    const tk = ls.get('sessionId')
    const usinfo = ls.get('userInfo')
    const organ = ls.get('organ')
    const shipper = ls.get('shipper')
    const team = ls.get('team')
    const employer = ls.get('employer')
    const employerList = ls.get('employerList')
    const menu = ls.get('menu')
    const uda_customers = ls.get('uda_customers')
    const uda_organs = ls.get('uda_organs')
    if (tk) {
      commit(types.USER_SET_AUTHENTICATE, true)
      commit(types.USER_SET_INFO, usinfo)
      commit(types.USER_SET_ORGAN, organ)
      commit(types.USER_SET_SHIPPER, shipper)
      commit(types.USER_SET_TEAM, team)
      commit(types.USER_SET_MENU, menu)
      commit(types.USER_SET_EMPLOYER, employer)
      commit(types.USER_SET_EMPLOYER_LIST, employerList)
      commit(types.USER_CUSTS, uda_customers)
      commit(types.USER_ORGANS, uda_organs)
      return Promise.resolve(true)
    } else {
      commit(types.USER_SET_AUTHENTICATE, false)
      return Promise.resolve(false)
    }
  },
  setAvatar({
    commit
  }, avatar) {
    //重新设置用户头像
    const userInfo = ls.get('userInfo')
    userInfo.profileImg = avatar
    ls.set('userInfo', userInfo)
    commit(types.USER_SET_AVATAR, avatar)
  },

  setMenuActiveOne({
    commit
  }, param) {
    //重新设置用户头像
    commit(types.USER_MENU_ACTIVE_ONE, param)
  },

  setMenuActive({
    commit
  }, param) {
    //重新设置用户头像
    commit(types.USER_MENU_ACTIVE, param)
  }
}



const mutations = {
  [types.USER_SET_AUTHENTICATE](state, authenticated) {
    state.authenticated = authenticated
  },
  [types.USER_LOGOUT](state) {
    state.authenticated = false
    state.userinfo = {}
    state.organ = {}
    state.shipper = {}
    state.employer = {}
    state.team = {}
    state.menu = {}
  },
  [types.USER_SET_INFO](state, userinfo) {
    state.userinfo = userinfo
  },
  [types.USER_SET_ORGAN](state, organ) {
    state.organ = organ
  },
  [types.USER_SET_SHIPPER](state, shipper) {
    state.shipper = shipper
  },

  [types.USER_SET_EMPLOYER](state, employer) {
    state.employer = employer
  },
  [types.USER_SET_EMPLOYER_LIST](state, employerList) {
    state.employerList = employerList
  },
  [types.USER_SET_TEAM](state, team) {
    state.team = team
  },
  [types.USER_SET_AVATAR](state, avatar) {
    state.userinfo.profileImg = avatar
  },
  [types.USER_SET_MENU](state, menu) {
    state.menu = menu
  },
  [types.USER_CUSTS](state, uda_customers) {
    state.uda_customers = uda_customers
  },
  [types.USER_ORGANS](state, uda_organs) {
    state.uda_organs = uda_organs
  },
  [types.USER_MENU_ACTIVE_ONE](state, param) {
    if (param.active) {
      state.menu.forEach((item) => {
        Vue.set(item, 'active', false)
        if (item.id == param.item.id) {
          Vue.set(item, 'active', true)
        }
      });
    } else {
      Vue.set(state.menu.find((item) => item.id === param.item.id), 'active', false)
    }
  },
  [types.USER_MENU_ACTIVE](state, param) {
    Vue.set(param.item, 'active', param.active)
  },
}



export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}