<template>
  <div class="left-menu">
    <el-scrollbar ref="menuScroller" class="menu-scroll">
      <div class="console-sec-menu">
        <el-scrollbar ref="menuScroller" class="menu-scroll">
          <!-- <div v-for="menu in frontTitles" :key="menu.text" class="console-sec-menu-text" @click="rootMenuClick(menu)">
            <template v-if="menu.url && menu.url.endsWith('.html') && defaultRootMenuData[menu.mxtype]">
              <i :class="defaultRootMenuData[menu.mxtype]['icon']" class="icon-size"></i>
            </template>
            &nbsp;
            {{ menu.text }}
          </div> -->
          <!-- <div class="console-sec-menu-text active">
            <i :class="defaultRootMenuData[menuData.mxtype]['icon']" class="icon-size"></i>
            &nbsp;
            {{ menuData.text }}
          </div> -->
          <el-menu class="tower-menu" background-color="#fff" router text-color="#333" active-text-color="#1492ff" :default-active="active" :collapse="collapse" :collapse-transition="true" :unique-opened="false" mini-style>
            <tree :data="menuItem" :show-icon="false" />
          </el-menu>
          <!-- <div v-for="menu in backTitles" :key="menu.text" class="console-sec-menu-text" @click="rootMenuClick(menu)">
            <template v-if="menu.url && menu.url.endsWith('.html') && defaultRootMenuData[menu.mxtype]">
              <i :class="defaultRootMenuData[menu.mxtype]['icon']" class="icon-size"></i>
            </template>
            &nbsp;
            {{ menu.text }}
          </div> -->
        </el-scrollbar>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Tree from "./tree";
import ConsoleMenuSearch from "./ConsoleMenuSearch.vue";
export default {
  name: "ConsoleMenu",
  components: {
    Tree,
    ConsoleMenuSearch,
  },
  props: {
    menuData: {
      type: Object,
    },
  },
  computed: {
    active: function () {
      return this.$route.path;
    },
    menuItem: function () {
      // return this.menuData.children;
      return this.menuData;
    },
    ...mapGetters({
      rootMenu: "user/menu",
    }),
    validMenu() {
      return this.rootMenu.filter((item) => item.children && item.hidden === 0);
    },
    frontTitles() {
      const activeIndex = this.validMenu.findIndex((item) => item.url === this.pathname);
      return this.validMenu
        .slice(0, activeIndex)
        .map((item) => ({ text: item.text, url: item.url, mxtype: item.mxtype }));
    },
    backTitles() {
      const activeIndex = this.validMenu.findIndex((item) => item.url === this.pathname);
      return this.validMenu
        .slice(activeIndex + 1)
        .map((item) => ({ text: item.text, url: item.url, mxtype: item.mxtype }));
    },
  },

  data() {
    return {
      showSearch: false,
      collapse: false,
      pathname: "",
      defaultRootMenuData: {
        home: {
          icon: "el-icon-house",
          shortName: "首页",
        },
        agent: {
          icon: "el-icon-suitcase-1",
          shortName: "网代",
        },
        bid: {
          icon: "el-icon-suitcase",
          shortName: "招投标",
        },
        common: {
          icon: "el-icon-setting",
          shortName: "基础",
        },
        wlhy: {
          icon: "el-icon-truck",
          shortName: "调车",
        },
        manage: {
          icon: "el-icon-set-up",
          shortName: "运营",
        },
        tms: {
          icon: "el-icon-connection",
          shortName: "运输",
        },

        wms: {
          icon: "el-icon-box",
          shortName: "仓储",
        },
        datav: {
          icon: "el-icon-data-board",
          shortName: "可视化",
        },
        data: {
          icon: "el-icon-data-analysis",
          shortName: "数据云",
        },
        tower: {
          icon: "el-icon-film",
          shortName: "控制塔",
        },
        gr: {
          icon: "el-icon-bank-card",
          shortName: "共融",
        },

        insu: {
          icon: "el-icon-lock",
          shortName: "保险",
        },

        department: {
          icon: "el-icon-office-building",
          shortName: "部门管理",
        },
      },
    };
  },
  methods: {
    ...mapActions(["user/setMenuActiveOne", "user/setMenuActive"]),
    menuSelect(index) {
      menuClickLog(this, index);
    },
    rootMenuClick(item) {
      window.open(item.url, item.text);
    },
    searchMenu(event) {
      if (event.target.type == "text") {
        event.stopPropagation();
        return;
      }
      this.showSearch = !this.showSearch;
    },
    inSearchMenu(event) {
      if (!this.showSearch) {
        this.showSearch = true;
      }
    },
    outSearchMenu(event) {
      if (event.target.offsetParent == null) {
        this.showSearch = false;
      }
      if (event.target.offsetParent && !event.target.offsetParent.classList.contains("left-more-menu")) {
        this.showSearch = false;
      }
    },
  },
  mounted() {
    this.pathname = window.location.pathname;
  },
};
</script>

<style lang="scss">
.left-menu {
  width: 50px;
}

.el-submenu .is-active .is-opened {
  .submenu__title {
    padding: 0 30px;
  }
}

.console-sec-menu-text {
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  font-size: 14px;
  cursor: pointer;
}

.console-sec-menu-text:hover {
  background-color: $--color-hover;
}

.console-sec-menu-text.active {
  background: linear-gradient(90deg, #84c3fa 0%, $--color-primary 100%);
  color: #fff;
  position: relative;
}
.console-sec-menu-text.active::after {
  content: ">";
  position: absolute;
  right: 13px;
  top: 0px;
  width: 8px;
  height: 100%;
  transform: rotateZ(270deg) scaleY(1.5);
}

.console-sec-menu-text .icon-size {
  font-size: 18px;
  color: #000;
  opacity: 0.6;
}

.console-sec-menu-text.active .icon-size {
  color: #fff;
}

.console-sec-menu {
  position: fixed;
  width: 180px;
  background: #fff;
  /* left: 50px; */
  color: #333;
  top: 50px;
  bottom: 0;
}

/*
  .console-sec-menu::before {
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-right: 6px solid transparent;
    border-right-color: rgb(255 255 255 / 0.90);
    content: '';
    position: absolute;
    left: -12px;
    top: 10px;
  } */

// .console-sec-menu .el-submenu .el-menu-item {
//   min-width: 100px;
// }
.tower-menu > .el-submenu > div:first-child {
  padding-left: 0 !important;
}
.tower-menu .el-submenu > .el-menu{
  padding-left: 7px;
}

.tower-menu > .is-active > div:first-child {
  background: linear-gradient(90deg, #84c3fa 0%, $--color-primary 100%) !important;
  color: #ffffff !important;
  .el-icon-setting{
    color: #fff !important;
  }
  .el-icon-set-up{
    color: #fff !important;
  }
  .el-icon-office-building{
    color: #fff !important;
  }
  .el-icon-connection{
    color: #fff !important;
  }
  .el-icon-truck{
    color: #fff !important;
  }
  .el-icon-suitcase-1{
    color: #fff !important;
  }
  .el-icon-box{
    color: #fff !important;
  }
  .el-icon-arrow-down{
    color: #fff !important;
  }
}
.tower-menu > .is-active > div:first-child >span{
  color: #ffffff !important;
}
.console-sec-menu .el-submenu.is-active > .el-submenu__title {
  color: $--color-primary !important;
}

.console-sec-menu .el-menu-item {
  height: 40px;
  line-height: 40px;
  font-size: 14px;
}

.console-sec-menu .el-submenu__title {
  height: 40px;
  line-height: 40px;
  font-size: 14px;
}

.console-sec-menu .el-submenu__icon-arrow {
  right: 10px;
  color: #000;
  font-weight: bold;
  opacity: 0.7;
}

.console-sec-menu .el-submenu.is-active > .el-submenu__icon-arrow {
  color: $--color-primary;
}

.sec-menu {
  display: inline-block;
  width: 100px;
  top: 0;
  position: relative;
  vertical-align: top;
  color: #fff;
  padding: 10px;
  overflow: hidden;
}

.sec-menu-scroll .el-scrollbar__wrap {
  overflow-x: hidden;
}

.sec-menu-scroll {
  height: 500px;
  overflow: hidden;
}

.sec-menu span {
  padding: 5px;
  display: block;
}

.tower-menu {
  // margin-left: 10px;
}
</style>
