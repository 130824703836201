<template>
    <el-dialog :visible.sync="visible" :before-close="beforeClose" @opened="handleOpened" :close-on-click-modal="false"
        :modal="false">
        <el-radio-group v-model="map" @change="handleChange">
            <el-radio-button label="bmap">百度地图</el-radio-button>
            <el-radio-button label="amap">高德地图</el-radio-button>
        </el-radio-group>
        <div id="addressMap"></div>
        <el-input id="suggestId" v-if="map=='bmap'" v-model="suggestText" placeholder="请输入关键字" maxlength="20">
        </el-input>
        <el-input id="asuggestId" v-if="map=='amap'" v-model="asuggestText" placeholder="请输入关键字" maxlength="20">
        </el-input>

    </el-dialog>

</template>
<script>
    import BMap from "BMap";
    import AMap from "AMap";
    import {
        bmapMixin
    } from "@/mixins/bmap-mixins";
    import {
        amapMixin
    } from "@/mixins/amap-mixins";
    import aEntityAPI from "@/api/aEntityAPI";
    export default {
        mixins: [bmapMixin, amapMixin],
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            beforeClose: {
                type: Function
            },
            address: {
                type: Object
            },
            readOnly: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                map: 'bmap',
                suggestText: "",
                asuggestText: "",
                initPoint: {
                    gpsX: null, //百度坐标经度
                    gpsY: null, //百度坐标纬度
                    lonGcj: null, //高德坐标经度
                    latGcj: null //高德坐标经度
                },
                aPlaceSearch: null,
            }
        },

        methods: {
            handleChange(val) {
                debugger
                this.aAuto = null

                if (val == 'bmap') {
                    this.$nextTick(() => {
                        this.bMapC()
                    })
                } else {
                    this.$nextTick(() => {
                        this.aMapC()
                    })
                }
            },
            handleOpened() {
                this.initPoint.gpsX = this.address.gpsX
                this.initPoint.gpsY = this.address.gpsY
                this.initPoint.lonGcj = this.address.lonGcj
                this.initPoint.latGcj = this.address.latGcj
                if (this.initPoint.lonGcj == null && this.initPoint.latGcj == null) {
                    this.map = 'bmap'
                }


                if (this.map == 'bmap') {
                    this.$nextTick(() => {
                        this.bMapC()
                    })
                } else {
                    this.$nextTick(() => {
                        this.aMapC()
                    })
                }



            },
            bMapC() {
                this.bmapInit("addressMap");

                var that = this;
                var ac = new BMap.Autocomplete({
                    //建立一个自动完成的对象
                    input: "suggestId",
                    location: that.bmap_map
                });

                var myValue;
                ac.addEventListener("onconfirm", function (e) {
                    //鼠标点击下拉列表后的事件
                    var _value = e.item.value;
                    myValue =
                        _value.province +
                        _value.city +
                        _value.district +
                        _value.street +
                        _value.business;

                    that.bmap_map.clearOverlays(); //清除地图上所有覆盖物
                    function myFun() {
                        var pp = local.getResults().getPoi(0).point; //获取第一个智能搜索的结果
                        that.bmap_map.centerAndZoom(pp, 18);
                        that.bmap_map.addOverlay(new BMap.Marker(pp)); //添加标注
                    }
                    var local = new BMap.LocalSearch(that.bmap_map, {
                        //智能搜索
                        onSearchComplete: myFun
                    });
                    local.search(myValue);
                });

                var point;
                if (this.initPoint.gpsX && this.initPoint.gpsY) {
                    point = new BMap.Point(
                        this.initPoint.gpsX,
                        this.initPoint.gpsY
                    );
                    that.bmapCreate(point);
                } else if (that.lastGPS) {
                    //之前有过定位，用之前的
                    //that.bmapCreate(that.lastGPS);
                } else {
                    //定位
                    var geolocation = new BMap.Geolocation();
                    //geolocation.enableSDKLocation();
                    geolocation.getCurrentPosition(
                        function (r) {
                            if (this.getStatus() == 0) {
                                point = r.point;
                                //that.lastGPS = point;
                                that.bmapCreate(point);
                            } else {
                                //定位失败
                                point = new BMap.Point(116, 39);
                                that.bmapCreate(point);
                            }
                        }, {
                            enableHighAccuracy: true
                        }
                    );
                }

                this.$emit('opened')
            },
            aMapC() {
                this.amapInit("addressMap");

                this.amap_map.clearMap()
                var that = this;
                //输入提示
                var autoOptions = {
                    input: "asuggestId"
                };

                AMap.plugin(['AMap.PlaceSearch', 'AMap.AutoComplete'], function () {
                    that.aAuto = new AMap.AutoComplete(autoOptions);
                    var placeSearch = new AMap.PlaceSearch({
                        map: that.amap_map,

                    }); //构造地点查询类
                    AMap.Event.addListener(placeSearch, "markerClick", ((e) => {

                        that.aMapPointConvertB(e.data.location, e.data.address,
                            true);

                    })); //返回结果
                    that.aAuto.on("select", select); //注册监听，当选中某条记录时会触发
                    function select(e) {
                        placeSearch.setCity(e.poi.adcode);
                        placeSearch.search(e.poi.name); //关键字查询查询
                    }
                });

                var point;
                if (this.initPoint.lonGcj && this.initPoint.latGcj) {
                    point = new AMap.LngLat(this.initPoint.lonGcj, this.initPoint.latGcj, false)
                    this.amapCreate(point);
                } else if (this.lastGPS) {
                    //之前有过定位，用之前的
                    //that.bmapCreate(that.lastGPS);
                } else {
                    //定位
                    AMap.plugin('AMap.Geolocation', function () {
                        var geolocation = new AMap.Geolocation({
                            enableHighAccuracy: true, //是否使用高精度定位，默认:true
                            timeout: 10000, //超过10秒后停止定位，默认：5s
                            buttonPosition: 'RB', //定位按钮的停靠位置
                            buttonOffset: new AMap.Pixel(10, 20), //定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
                            zoomToAccuracy: true, //定位成功后是否自动调整地图视野到定位点

                        });
                        that.amap_map.addControl(geolocation);
                        geolocation.getCurrentPosition(function (status, result) {
                            if (status == 'complete') {
                                that.amapCreate(result.position);
                            } else {
                                point = new BMap.Point(116, 39);
                                that.amapCreate(point);
                            }
                        });
                    });

                }


                this.$emit('opened')

            },


            bmapCreate(point) {
                this.bmap_map.centerAndZoom(point, 12);
                this.bmap_map.enableScrollWheelZoom(true);
                this.bmap_map.enableDragging();

                const marker = new BMap.Marker(point);
                this.bmap_map.addOverlay(marker);

                this.bmap_map.addEventListener("click", e => {
                    if (this.readOnly) return;

                    //移动到所选的地址
                    this.bmap_map.panTo(e.point, true);
                    marker.setPosition(e.point);

                    var myGeo = new BMap.Geocoder();
                    //百度地图根据选择坐标点解析位置
                    myGeo.getLocation(e.point, result => {
                        if (result) {
                            this.bMapPointConvertA(e.point, result.address)
                        }
                    });
                });
            },
            amapCreate(point) {
                const that = this;
                let confirm = false;
                AMapUI.loadUI(['misc/PositionPicker'], (PositionPicker) => {

                    var positionPicker = new PositionPicker({
                        mode: 'dragMap', //设定为拖拽地图模式，可选'dragMap'、'dragMarker'，默认为'dragMap'
                        map: that.amap_map //依赖地图对象
                    });

                    //TODO:事件绑定、结果处理等
                    positionPicker.on('success', (positionResult) => {

                        debugger
                        //坐标转换 并 获取区划数据 返回 地址数据
                        this.aMapPointConvertB(positionResult.position, positionResult.address,
                            confirm);

                    });
                    positionPicker.on('fail', function (positionResult) {

                    });

                    positionPicker.setMode('dragMarker')
                    //AMap.LngLat(lng:Number,lat:Number,noAutofix:bool)

                    positionPicker.start(point);

                    that.amap_map.on('click', function (e) {
                        confirm = true
                        positionPicker.start(e.lnglat);
                    })

                });

            },
            //高德坐标转百度坐标
            aMapPointConvertB(point, address, confirm) {
                var arr = [];
                arr.push(point)
                if (confirm) {
                    var convertor = new BMap.Convertor();
                    const COORDINATES_GCJ02 = 3; //高德坐标系
                    const COORDINATES_BD09 = 5; //百度坐标系
                    convertor.translate(arr, COORDINATES_GCJ02, COORDINATES_BD09, ((data) => {
                        if (data.status === 0) {
                            const param = {
                                lng: data.points[0].lng,
                                lat: data.points[0].lat
                            };
                            const mixPint = {
                                lonGcj: point.lng, //高德
                                latGcj: point.lat, //高德
                                gpsX: data.points[0].lng, //百度
                                gpsY: data.points[0].lat //百度
                            }
                            aEntityAPI
                                .request('ShipperAddrService', 'getRegionByStr', param).then(res => {
                                    if (res.data.success) {
                                        const result = res.data.data;
                                        result.address = address

                                        this.$emit('selected', result, mixPint)

                                    } else {
                                        this.$emit('selected', {address:null}, mixPint)
                                        // this.$message({
                                        //     showClose: true,
                                        //     message: res.data.msg ? res.data.msg : "获取地址区划失败",
                                        //     type: "warning"
                                        // });
                                    }
                                });
                        }
                    }))
                }



            },
            //百度坐标转高德地图
            bMapPointConvertA(point, address) {
                var arr = [];
                arr.push(point)
                var convertor = new BMap.Convertor();
                const COORDINATES_GCJ02 = 3; //高德坐标系
                const COORDINATES_BD09 = 5; //百度坐标系
                convertor.translate(arr, COORDINATES_BD09, COORDINATES_GCJ02, ((data) => {
                    if (data.status === 0) {
                        const param = {
                            lng: point.lng,
                            lat: point.lat
                        };
                        const mixPint = {
                            lonGcj: data.points[0].lng, //高德
                            latGcj: data.points[0].lat, //高德
                            gpsX: point.lng, //百度
                            gpsY: point.lat //百度
                        }
                        aEntityAPI
                            .request('ShipperAddrService', 'getRegionByStr', param).then(res => {
                                if (res.data.success) {
                                    const result = res.data.data;
                                    result.address = address

                                    this.$emit('selected', result, mixPint)

                                } else {
                                    this.$emit('selected', {address:null}, mixPint)
                                    // this.$message({
                                    //     showClose: true,
                                    //     message: res.data.msg ? res.data.msg : "获取地址区划失败",
                                    //     type: "warning"
                                    // });
                                }
                            });
                    }
                }))
            },


        }
    }
</script>
<style>
 #addressMap {
    height: 400px;
  }

    .amap-sug-result {
        z-index: 99999;
    }
    .tangram-suggestion-main{
        z-index: 99999;
    }
</style>