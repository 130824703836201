<template>
  <div id="app">
    <div class="overflow-hidden">
      <router-view name="header" ref="header" />
      <div class="app-content">
        <el-scrollbar ref="mainScroller" class="main-scroll">
          <div :class="['home', { 'is-collapse': isCollapse }]">
            <div class="left">
              <!--  左侧菜单 -->
              <router-view name="menu" />
            </div>

            <div class="main">
              <!-- tab页-->
              <router-view name="pageList" />
              <!-- <page-list></page-list> -->
              <!-- 主体部分 :params="$route.params" -->
              <keep-alive>
                <router-view name="content" :key="$route.path" />
              </keep-alive>
            </div>
          </div>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters({
      isCollapse: "layout/isCollapse",
      sysConfig: "config/sysConfig",
    }),
  },
  data() {
    return {
      hrsaasImg: require("@/assets/hrsaas/index/hr_saas_logo.png"),
      saasImg: require("@/assets/saas_logo.png"),
    };
  },
  methods: {
    ...mapActions(["user/checkLogin", "config/checkConfig", "layout/setScrollTop"]),
    handleScroll() {
      let scrollbarEl = this.$refs.mainScroller.wrap;
      scrollbarEl.onscroll = () => {
        this["layout/setScrollTop"](scrollbarEl.scrollTop);
      };
    },

    queryTaskCount() {
      // alert('app.testInvoke')
      this.$refs.header.queryTaskCount();
    },
  },
  watch: {
    // sysConfig: function(nval, oval) {
    //   if (nval && nval.logoFile && nval.logoFile.filePath) {
    //     var link = document.createElement("link");
    //     link.type = "image/x-icon";
    //     link.rel = "shortcut icon";
    //     link.href =  this.__ftpPath + nval.logoFile.filePath;
    //     document.getElementsByTagName("head")[0].appendChild(link);
    //   }
    // }

    sysConfig: function (nval, oval) {
      if (nval && nval.logoFile && nval.logoFile.filePath) {
        var link = document.createElement("link");
        link.type = "image/x-icon";
        link.rel = "shortcut icon";

        var serverHost = window.location.host;
        if (serverHost.indexOf("hrsaas") > -1 || serverHost.indexOf("saas.gz-vip.com") > -1) {
          link.href = this.hrsaasImg;
        } else if (serverHost.indexOf("saas") > -1) {
          link.href = this.saasImg;
        } else {
          link.href = this.__ftpPath + nval.logoFile.filePath;
        }
        document.getElementsByTagName("head")[0].appendChild(link);
      }
    },
  },
  created() {},
  mounted() {
    this["user/checkLogin"]();
    this["config/checkConfig"]();

    //监听滚动
    this.handleScroll();
  },
};
</script>
<style>
html,
body {
  height: 100%;
}

#app {
  height: 100%;
}

.overflow-hidden {
  height: 100%;
  overflow: hidden;
}

.app-content {
  height: 100%;
  padding: 0;
  margin-top: 0;
  height: 100%;
  min-height: auto;
}

.home {
  position: relative;
  top: 51px;
  position: relative;
  height: 100%;
}

.left {
  position: fixed;
  width: 180px;
  left: 0;
  top: 50px;
  bottom: 0px;
  z-index: 8;
  box-shadow: 2px 0 8px 0 rgba(0, 0, 0, 0.2);
}

.home.is-collapse > .left {
  width: 64px;
}

.main {
  position: relative;
  left: 195px;
  right: 15px;
  top: 55px;
  width: calc(100% - 210px);
}

.main-scroll {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.home.is-collapse > .main {
  left: 79px;
  width: calc(100% - 94px);
}

.main-scroll > .el-scrollbar__wrap {
  overflow-x: hidden;
}

.main-scroll > .el-scrollbar__wrap > .el-scrollbar__view .main > div {
  min-width: 1450px;
}

.main-scroll > .el-scrollbar__wrap > .el-scrollbar__view .main > div.grid-wrap {
  min-width: unset;
}

.main-scroll > .el-scrollbar__wrap > .el-scrollbar__view .main > div.unset-min-width {
  min-width: unset;
}

.main-scroll > .el-scrollbar__bar.is-horizontal:hover {
  height: 10px;
}

.main-scroll > .el-scrollbar__bar.is-vertical:hover {
  width: 10px;
}
</style>
