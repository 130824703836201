export default {
  name: 'tree',
  functional: true,
  props: {
      data: Array,
      prefix:String,
      showIcon:Boolean
  },
  render: (createElement, ctx) => {
      const data = ctx.props.data;
      const  showIcon = ctx.props.showIcon;
      if(data && data.length>0){
        return data.map(function(item){
          if(item.children && item.hidden==0){
            return createElement('el-submenu',{
              props:{
                index: item.url?item.url:item.id
              },
              attrs:{
                mxtype: item.mxtype
              }
            },[
              createElement('template',{
                slot:'title',
              },[
                // showIcon?createElement('i',{class:item.iconCls}):null,
                item.text=='运营系统'?createElement('i',{class:item.iconCls}):null,
                item.text=='基础运维'?createElement('i',{class:item.iconCls}):null,
                item.text=='部门管理'?createElement('i',{class:item.iconCls}):null,
                item.text=='运输系统'?createElement('i',{class:item.iconCls}):null,
                item.text=='网络货运'?createElement('i',{class:item.iconCls}):null,
                item.text=='车网代'?createElement('i',{class:item.iconCls}):null,
                item.text=='仓储系统'?createElement('i',{class:item.iconCls}):null,
                item.text=='数据可视化'?createElement('i',{class:item.iconCls}):null,
                createElement('span',{attrs:{title:item.text}}, item.text),
              ]),
              createElement('tree',{props:{data:item.children}})
            ])
          }else{
            if(item.hidden==0){//过滤隐藏菜单,显示叶子菜单（关键菜单代码）
              return createElement('el-menu-item',  {
                props:{
                  index: item.url,
                  route: {path:item.url},
                  menuItem: item //add by zx 2022-8-24
                },
                attrs:{
                  mxtype:item.mxtype
                }
              },[
                // showIcon?createElement('i',{class:item.iconCls}):null,
                item.text=='运营系统'?createElement('i',{class:item.iconCls}):null,
                item.text=='基础运维'?createElement('i',{class:item.iconCls}):null,
                item.text=='部门管理'?createElement('i',{class:item.iconCls}):null,
                item.text=='运输系统'?createElement('i',{class:item.iconCls}):null,
                item.text=='网络货运'?createElement('i',{class:item.iconCls}):null,
                item.text=='车网代'?createElement('i',{class:item.iconCls}):null,
                item.text=='仓储系统'?createElement('i',{class:item.iconCls}):null,
                createElement('span',{slot:'title',attrs:{title:item.text}}, item.text),
              ])
            }
            
          }
        })
         
      }
      
  }
};