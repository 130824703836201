import aes from '../login/aes.js';
import ls from "../utils/ls";


!(function () {

    XMLHttpRequest.prototype.nativeOpen = XMLHttpRequest.prototype.open;
    var customizeOpen = function (method, url, async = true, user, password) {
        this.nativeOpen(method, url, async, user, password);
        if(url != 'https://miao.baidu.com/abdr'){//自定义的header会导致 百度地图的请求跨域，排除
            this.setRequestHeader('TenantDomain', window.location.hostname);

            var routerStart = window.location.href.indexOf('.html#')
            if(routerStart>-1){
                var refererRouter = window.location.href.substr(routerStart+6)
                this.setRequestHeader('refererRouter', refererRouter);
            }

            //埋点请求不需要带token
            if(url.indexOf('recordLog') == -1){

                //csrfToken = csrfRootToken + 请求的序列号
                var token = ls.get('csrfToken')            
                
                //csrfSeq
                var seq = 1
                var seqStr = ls.get('csrfSeq')
                if(seqStr){
                    seq = parseInt(seqStr)
                    seq++
                }
                ls.set('csrfSeq',seq)
                
                //加密推送
                var tokenJson = {
                    'a1': Math.random().toString(20) ,
                    'token': token,
                    'b2': Math.random().toString(20) , 
                    'seq': seq
                }    
                var tokenStr = aes.encrypt(tokenJson) 
                // console.log(tokenJson)       
                // console.log(tokenStr)
                this.setRequestHeader('csrfToken', aes.encrypt(JSON.stringify(tokenJson)));
            }
        }
       
    };

    XMLHttpRequest.prototype.open = customizeOpen;
}());