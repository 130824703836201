

import store from '@/store'
const _util = {
    
    hasPermission( url, permission) {
        const $menus = store.getters && store.getters['user/menu']
        var result = { hasPmss: false, node: null }
        this.visitPermissionTree($menus, url, permission, result)
        return result.hasPmss
    },

    visitPermissionTree(nodes, url, permission, result) {
 
        //无权限才继续遍历
        if (result.hasPmss == true)
            return
            
        for (var i = 0; i < nodes.length; i++) {
            
            var node = nodes[i]

            if(node.url && node.url === url ){
                
                if (permission) {
                    if (permission === '*') {
                        result.hasPmss = true;
                        continue
                    }
                    result.hasPmss = node.permission ? node.permission.indexOf(permission) > -1 : false
                } else { /*无设置permission，所有页面都会返回 */
                    result.hasPmss = true
                }
            }

            if (node.children) {
                this.visitPermissionTree(node.children, url, permission, result)
            }
        }
    }
}

export {_util}