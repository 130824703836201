<template>
    <div :class="['gs-box',customerClass]">
        <div class="gs-box-title">
            <slot name="title" >{{title}}</slot>
        </div>
        <div class="gs-box-body">
            <slot name="body"></slot>
        </div>
    </div>
</template>
<script>
    export default{
        props: {
            title: { 
                type: String
            },
            customerClass:{
                type: String
            }
        }
    }
</script>
<style>
.gs-box{
    background: #fff;
}
.gs-box+.gs-box{
    margin-top: 15px;
}
.gs-box-title{
    padding: 10px;
    font-weight: bold;
    font-size: 14px;
}
.gs-box-body{
    padding: 0 10px 10px 10px;
}
.gs-box-body>.grid-wrap {
    box-shadow: none;
    padding: 0px;
}
</style>