import Vue from "vue";

export const activityList = () => {
  return Vue.http(`api/NewsService/getNewsList?dictCode=TX_NEWS_TYPE_TREND`);
};

export const announceList = () => {
  return Vue.http(`api/NewsService/getNewsList?dictCode=TX_NEWS_TYPE_PUSH`);
};

export const newsDetail = (id) => {
  return Vue.http(`api/NewsService/getNewsList?id=${id}`);
};

export const docList = () => {
  return Vue.http(`api/NewsService/getNewsList?rules=1&rules=5&baseType=5`);
};

export const companyRank = (type = "") => {
  return Vue.http(`api/ShipperService/findShipperRanking?companyType=${type}`);
};

export const dataPlatform = () => {
  return Vue.http(`api/ShipperService/queryShipperIndex`);
};

/**
 * @param {string} type WAREHOUSE_COMPANY === 仓储企业
 * @returns
 */
export const testCompany = (type) => {
  return Vue.http(`api/ShipperService/queryExperShipper?companyType=${type}`);
};

/**
 * @param {string} type 3（竞价货源） 5（抢单货源）
 * @returns
 */
export const sourceList = (type) => {
  return Vue.http(`api/BidService/queryBidList?bidPriceMode=${type}`);
};

export const transSourceList = () => {
  return Vue.http(`api/ShipperService/findShipperCapacity`);
};

export const companyInfo = () => {
  return Vue.http(`api/SysCompanyService/getCompanyData`);
};

export const boradcastList = () => {
  return Vue.http(`api/SysCompanyService/broadCastList?type=1`);
};

export const privacyContract = () => {
  return Vue.http(`api/PrcTransportService/getContractDetail?type=20`);
};

export const coopPartner = () => {
  return Vue.http(`api/SysCompanyService/queryYjyHpConfig`);
};

export const aboutTitle = () => {
  return Vue.http(`api/SysCompanyService/queryBroadCast?type=10`);
};
